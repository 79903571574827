import React from 'react';
import styled, { createGlobalStyle, ThemeProvider } from 'styled-components';
import { StickyContainer } from 'react-sticky';

import Navigation from './navigation';
import themeDefault from './UIKit/theme';
import { fontFamilySans, getThemeColor } from '../utils/styles';
import Footer from './footer';
import SEO from './seo';
import Responsive from './responsive';
import GlobalCtaBlock from './global-cta-block';

const GlobalStyle = createGlobalStyle`
  body {
    ${fontFamilySans};
    color: ${getThemeColor('black')};
  }
  
  *, *:before, *:after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  h1, h2, h3, h4, h5, h6 {
    margin: 0;
  }
`;

const Wrapper = styled.div`
  overflow-x: hidden;
`;

function Layout({
  children,
  seoTitle,
  seoDescription,
  seoSharingImage,
  navigationEditorContent,
  footerEditorContent,
  noCtaBlock,
}) {
  return (
    <ThemeProvider theme={themeDefault}>
      <StickyContainer>
        <Responsive.Provider>
          <SEO
            title={seoTitle}
            description={seoDescription}
            sharingImage={seoSharingImage}
          />
          <GlobalStyle />
          <Wrapper>
            <Navigation editorContent={navigationEditorContent} />
            {children}
            {!noCtaBlock && <GlobalCtaBlock />}
            <Footer footerEditorContent={footerEditorContent} />
          </Wrapper>
        </Responsive.Provider>
      </StickyContainer>
    </ThemeProvider>
  );
}

export default Layout;
