import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

import Link from '../link';
import { getThemeColor, hoverDefault } from '../../utils/styles';
import ChevronDown from '../../images/svg/chevron-down.svg';
import Dropdown from './dropdown';
import { func } from 'prop-types';

const StyledNavItem = styled.div`
  margin-right: 33px;

  &:last-of-type {
    margin-right: 0;
  }
  @media (max-width: 1070px) {
    margin-right: 17px;
  }
`;

const StyledLink = styled(Link)`
  ${hoverDefault};
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 14px;
  cursor: pointer;
  outline: none;
  letter-spacing: 0;

  ${(props) =>
    props.alternativeStyling &&
    css`
      font-weight: 400;
      color: #666;
    `};
`;

const StyledChevronDown = styled(ChevronDown)`
  fill: ${getThemeColor('primary')};
  margin-left: 7px;

  ${(props) =>
    props.flipped &&
    css`
      transform: scaleY(-1);
    `};
`;

function NavItem({
  to,
  children,
  alternative_color,
  dropdown,
  alternativeStyling,
  isEditorContent,
  dropdownIsVisible,
  onSetDropdownName,
}) {
  function handleClick(e) {
    e.preventDefault();
    dropdownIsVisible && onSetDropdownName('');
    !dropdownIsVisible && onSetDropdownName(children);
  }

  function handleMouseOver(e) {
    e.preventDefault();
    onSetDropdownName && onSetDropdownName(children);
  }

  return (
    <StyledNavItem>
      <StyledLink
        className="nav-link"
        style={{ color: alternative_color && 'darkcyan  ' }}
        alternativeStyling={alternativeStyling}
        onClick={dropdown && handleClick}
        onMouseOver={dropdown && handleMouseOver}
        to={to}
      >
        {children}
        {!!dropdown && <StyledChevronDown flipped={dropdownIsVisible} />}
      </StyledLink>
      {!isEditorContent && dropdown && (
        <Dropdown show={dropdownIsVisible} {...dropdown} />
      )}
    </StyledNavItem>
  );
}

export default NavItem;
