import React from 'react';
import styled from 'styled-components';

import { Box, Flex, Text } from '../UIKit/system';
import Link from '../link';
import LazyImage from '../lazy-image';

const Image = styled(LazyImage)`
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url(${(props) => props.image});
  background-size: cover;
  transition: 1000ms;
  filter: brightness(60%);
`;

const StyledShortcutLink = styled(Link)`
  display: block;
  margin-right: 20px;
  background-size: cover;
  text-align: left;
  cursor: pointer;
  user-select: none;
  overflow: hidden;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    ${Image} {
      transform: scale(1.1);
    }
  }
`;

function ShortcutLink({ title, subtitle, image, ...rest }) {
  return (
    <StyledShortcutLink color={'#fff'} {...rest}>
      <Flex
        position={'relative'}
        p={14}
        alignItems={'flex-end'}
        width={210}
        height={150}
        bg={'black'}
        backgroundSize={'cover'}
      >
        <Image fluid={image} />
        <Box position={'relative'}>
          <Text fontSizeSmall>{subtitle}</Text>
          <Text bold>{title}</Text>
        </Box>
      </Flex>
    </StyledShortcutLink>
  );
}

export default ShortcutLink;
