import styled, { css } from 'styled-components';

import Link from '../link';
import { hoverDefault, supportsGrid } from '../../utils/styles';
import { Box } from '../UIKit/system';

export const ColumnsContainer = styled.div`
  max-width: 965px;
  flex: 1;
  margin-right: 60px;
`;

export const Columns = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  ${supportsGrid(css`
    display: grid;
    grid-template-columns: ${(props) => props.gridTemplateColumns};
    grid-column-gap: ${(props) => props.columnGap};
    grid-row-gap: 40px;
  `)};
`;

Columns.defaultProps = {
  equalColumnsNumber: 3,
};

export const Column = styled.div`
  ${(props) =>
    props.iconic &&
    css`
      display: grid;
      grid-template-rows: 0.5fr 1fr 1fr;

      ${props.alternativeStyling &&
      css`
        grid-template-rows: 58px 1fr;
      `}
    `};
`;

export const ColumnHeader = styled.div`
  font-weight: 700;
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;
`;

export const LinkContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  > * {
    margin-right: 15px;
    margin-bottom: 15px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  ${supportsGrid(css`
    display: grid;
    grid-gap: 15px;
    justify-items: flex-start;

    > * {
      margin-right: 0;
      margin-bottom: 0;
    }
  `)}
`;

export const LinkGroups = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 15px;
  grid-row-gap: 40px;
  grid-template-columns: ${(props) => props.gridTemplateColumns};
`;

export const ColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 26px;
`;

export const ColumnContentTitle = styled.div`
  font-weight: 700;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 14px;
  letter-spacing: 0;
`;

export const StyledLink = styled(Link)`
  ${hoverDefault};
  color: #666;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0;
`;

export const ColumnTitle = styled.div`
  font-weight: 700;
  margin-bottom: 10px;
  letter-spacing: 0;

  ${(props) =>
    props.noMarginBottom &&
    css`
      margin-bottom: 3px;
    `};
`;

export const LinkGroupTitle = styled(Link)`
  display: block;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  margin-bottom: 10px;
  letter-spacing: 0.05em;

  ${(props) =>
    !props.to &&
    css`
      pointer-events: none;
    `};
`;

export const IconContainer = styled(Box)`
  position: relative;
`;

export const Icon = styled.img`
  position: absolute;
  width: 26px;
  height: auto;
  margin-bottom: 10px;
  margin-left: 2px;
  transition: opacity 0.35s ease-out;

  ${(props) =>
    props.alternativeStyling &&
    css`
      width: 42px;
    `};
`;

export const IconHover = styled(Icon)`
  opacity: 0;
`;

export const StyledIconicColumnLink = styled(Link)`
  ${(props) =>
    props.hoverStateSupport &&
    css`
      &:hover {
        ${ColumnTitle} {
          text-decoration: underline;
        }

        ${Icon}, ${IconHover} {
          transition: none;
        }

        ${Icon} {
          opacity: 0;
        }

        ${IconHover} {
          opacity: 1;
        }
      }
    `};
`;
