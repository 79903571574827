import React from 'react';
import styled, { css } from 'styled-components';
import { height, letterSpacing, space } from 'styled-system';

import {
  button,
  buttonReset,
  fontFamilySans,
  getThemeColor,
  transitionDefault,
} from '../utils/styles';
import Link from './link';
import ChevronRightIcon from '../images/svg/chevron-right.svg';

const StyledButton = styled(Link)`
  ${transitionDefault};
  ${fontFamilySans};
  ${buttonReset};
  ${button};
  ${height};
  ${space};
  ${letterSpacing};
  letter-spacing: 0;

  &:hover {
    transition: none;
    background-color: ${getThemeColor('black')};

    &:active {
      opacity: 0.8;
    }
  }

  ${(props) =>
    props.inDarkBlock &&
    css`
      &:hover {
        background-color: #fff;
        color: ${getThemeColor('black')};
      }
    `};

  ${(props) =>
    props.borderColorPrimary &&
    css`
      background-color: transparent;
      border-color: ${getThemeColor('primary')(props)};
      color: #fff;

      &:hover {
        background-color: ${getThemeColor('primary')(props)};
      }
    `};

  ${(props) =>
    props.borderPrimaryColorPrimary &&
    css`
      background-color: transparent;
      border-color: ${getThemeColor('primary')(props)};
      color: ${getThemeColor('primary')(props)};

      &:hover {
        background-color: ${getThemeColor('primary')(props)};
        color: #fff;
      }
    `};

  ${(props) =>
    props.whiteTransparent &&
    css`
      background-color: transparent;
      border-color: #d4d4d4;
      color: ${getThemeColor('black')};

      &:hover {
        background-color: transparent;
        border-color: ${getThemeColor('primary')(props)};
        color: ${getThemeColor('primary')(props)};
      }
    `};

  ${(props) =>
    props.white &&
    css`
      background-color: #fff;
      color: ${getThemeColor('black')};
    `};

  ${(props) =>
    props.whitePurple &&
    css`
      background-color: #fff;
      color: ${getThemeColor('black')};
      &:hover {
        background-color: ${getThemeColor('purple')};

        color: #fff;
      }
    `};

  ${(props) =>
    props.transparent &&
    css`
      background-color: transparent;
      border-color: #474747;
      color: #fff;

      &:hover {
        background-color: #fff;
        border-color: #fff;
        color: ${getThemeColor('black')(props)};
      }
    `};

  ${(props) =>
    props.bigPadding &&
    css`
      padding: 0 72px;
    `};

  ${(props) =>
    props.small &&
    css`
      height: 36px;
      padding: 0 14px;
    `};

  ${(props) =>
    props.invalid &&
    css`
      border-color: ${getThemeColor('danger')(props)};
    `};

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.7;
    `};
`;

const StyledChevronRightIcon = styled(ChevronRightIcon)`
  fill: #fff;
  margin-left: 13px;
`;

function Button({ children, chevronRight, ...rest }) {
  return (
    <StyledButton {...rest}>
      {children}
      {chevronRight && <StyledChevronRightIcon />}
    </StyledButton>
  );
}

export default Button;
