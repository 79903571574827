const colorProxy =
  typeof Proxy !== 'undefined'
    ? (obj) => {
        return new Proxy(obj, {
          get(target, name) {
            if (name === '$$typeof') {
              return;
            }

            if (name === 'transparent') {
              return name;
            }

            if (
              process.env.NODE_ENV !== 'production' &&
              !(name in target) &&
              name[0] !== '#'
            ) {
              throw new Error(
                `Requested color ("${name}") was not found among the theme colors`
              );
            }

            return target[name];
          },
        });
      }
    : (x) => x;

export default colorProxy;
