import React from 'react';
import StoryblokClient from 'storyblok-js-client';

import STORYBLOK_API_KEY from '../storyblok-api-key';

let Storyblok = new StoryblokClient({
  accessToken: STORYBLOK_API_KEY,
});

function createMarkup(storyblokHTML) {
  const rendered = Storyblok.richTextResolver.render(storyblokHTML);
  return {
    __html: rendered,
  };
}

const RichTextField = ({ data }) => {
  return <div dangerouslySetInnerHTML={createMarkup(data)} />;
};

export default RichTextField;
