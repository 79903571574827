import { colors } from './colors';
import { BREAKPOINT_LG, BREAKPOINT_MD, BREAKPOINT_SM } from '../../constants';

const breakpoints = [BREAKPOINT_SM, BREAKPOINT_MD, BREAKPOINT_LG].map(
  (v) => `${(v + 1) / 16}em`
);

breakpoints.sm = BREAKPOINT_SM;
breakpoints.md = BREAKPOINT_MD;
breakpoints.lg = BREAKPOINT_LG;

export const createTheme = () => () => {
  return {
    breakpoints,
    space: 1,
    colors,
  };
};

const themeDefault = createTheme();

export default themeDefault;
