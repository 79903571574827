import colorProxy from '../../utils/color-proxy';

const black = '#161616';
const white = '#fff';
const purple = '#753fbf';
const vividPurple = '#8a55fb';
const primary = purple;
const vividPrimary = vividPurple;
const lightGrey = '#f8f8f8';
const grey = '#666';
const danger = '#d07c7c';

export const colors = colorProxy({
  bg: white,
  content: black,
  black,
  white,
  primary,
  purple,
  vividPurple,
  vividPrimary,
  lightGrey,
  grey,
  danger,
});
