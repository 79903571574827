import React from 'react';

import { Text } from './UIKit/system';

const fontSizes = {
  default: [32, 48, 52],
  medium: [32, 28, 38, 42],
  small: [20, 22, 24],
  extraSmall: [16, 18, 20],
  extraSmall2: [16, 18],
  extraSmall3: [16],
};

const Title = React.forwardRef(
  (
    {
      fontSizeMedium,
      fontSizeSmall,
      fontSizeExtraSmall,
      fontSizeExtraSmall2,
      fontSizeExtraSmall3,
      ...rest
    },
    ref
  ) => {
    let fontSize;
    if (fontSizeMedium) {
      fontSize = fontSizes.medium;
    } else if (fontSizeSmall) {
      fontSize = fontSizes.small;
    } else if (fontSizeExtraSmall) {
      fontSize = fontSizes.extraSmall;
    } else if (fontSizeExtraSmall2) {
      fontSize = fontSizes.extraSmall2;
    } else if (fontSizeExtraSmall3) {
      fontSize = fontSizes.extraSmall3;
    } else {
      fontSize = fontSizes.default;
    }

    return (
      <Text
        lineHeight={1.25}
        letterSpacing={'-0.02em'}
        fontSize={fontSize}
        bold
        {...rest}
        ref={ref}
      />
    );
  }
);

export default Title;
