import React from 'react';
import styled, { css } from 'styled-components';

import withLocation from './with-location';
import Link from './link';

const StyledLink = styled(Link)`
  ${(props) =>
    props.isHomePage &&
    css`
      pointer-events: none;
    `};
`;

function LogoLink({ children, location }) {
  const isHomePage = location.pathname === '/';

  return (
    <StyledLink to={isHomePage ? '#' : '/'} isHomePage={isHomePage}>
      {children}
    </StyledLink>
  );
}

export default withLocation(LogoLink);
