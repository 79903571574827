import React from 'react';
import styled, { css } from 'styled-components';

import Arrow from '../images/svg/arrow-right.svg';
import { getThemeColor, transitionDefault } from '../utils/styles';
import mq from '../utils/mq';

const ArrowContainer = styled.div`
  border: 1.2px solid #c4c4c4;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;

  ${(props) =>
    props.white &&
    css`
      border-color: #464646;
    `};

  ${(props) =>
    props.small &&
    css`
      width: 27px;
      height: 27px;
    `};

  ${(props) =>
    props.large &&
    css`
      width: 36px;
      height: 36px;

      ${mq.mobile(css`
        width: 32px;
        height: 32px;
      `)};
    `};

  ${(props) =>
    props.withHover &&
    css`
      &,
      svg {
        ${transitionDefault};

        &:hover {
          transition: none;
        }
      }

      &:hover {
        border-color: ${getThemeColor('primary')(props)};

        svg {
          stroke: ${getThemeColor('primary')(props)};
        }
      }
    `};

  ${(props) =>
    props.down &&
    css`
      transform: rotate(90deg);
    `};
`;

// eslint-disable-next-line
const StyledArrow = styled(({ small, white, large, ...rest }) => (
  <Arrow {...rest} />
))`
  stroke: #474747;

  ${(props) =>
    props.white &&
    css`
      stroke: #fff;
    `};

  ${(props) =>
    props.small &&
    css`
      width: 9px;
      height: 8px;
    `};

  ${(props) =>
    props.large &&
    css`
      width: 13px;
      height: 14px;

      ${mq.mobile(css`
        width: 12px;
        height: 11px;
      `)};
    `};

  ${(props) =>
    props.morePale &&
    css`
      stroke: #666;
    `};
`;

function ArrowInCircle({ white, small, large, morePale, ...rest }) {
  return (
    <ArrowContainer white={white} small={small} large={large} {...rest}>
      <StyledArrow
        white={white}
        small={small}
        large={large}
        morePale={morePale}
      />
    </ArrowContainer>
  );
}

export default ArrowInCircle;
