//TODO: Refactor this entire file, doesn't adhear to DRY.

const schema = [
  {
    id: 'bold',
    type: 'wrap',
    return: 'b',
  },
  {
    id: 'italic',
    type: 'wrap',
    return: 'i',
  },
  {
    id: 'strong',
    type: 'wrap',
    return: 'strong',
  },
  {
    id: 'underline',
    type: 'wrap',
    return: 'u',
  },
  {
    id: 'code',
    type: 'wrap',
    return: 'code',
  },
  {
    id: 'link',
    type: 'function',
    return: 'link',
  },
  {
    id: 'strike',
    type: 'wrap',
    return: 's',
  },
  {
    id: 'textStyle',
    type: 'function',
    return: 'span',
    handler (mark, text, wrapper) {

      const styleArr = mark.attrs ? Object.entries(mark.attrs).flatMap(([key, value]) => {
        return value ? [`${key}: ${value}`] : []
      }) : []

      const styleDef = `style="${styleArr.join('; ')}"`

      const textEl = `<${this.return} data-mark-handler="${this.id}" ${styleDef}>${text}</${this.return}>`

      return wrapper ? `<${wrapper}>${textEl}</${wrapper}>` : textEl
    }
  }
];

function linkParse(paragraph, mark) {
  let target;
  if (mark.attrs.target) {
    target = mark.attrs.target;
  } else {
    target = '_blank';
  }
  let linkString =
    `<a href="` +
    mark.attrs.href +
    `" target="` +
    target +
    `">` +
    paragraph.text +
    `</a>`;
  return linkString;
}

function markWrap(text, markItem) {
  let wrappedText =
    '<' + markItem.return + '>' + text + '</' + markItem.return + '>';
  return wrappedText;
}

export default function RichtextParser(text) {
  let htmlString = [];

  if (text.content === undefined) {
    return text;
  }

  text.content.map((item) => {
    if (item.type === 'paragraph') {
      let paragraphString = [];
      if (item.content === undefined) {
        paragraphString.push('<p><br></p>');
      } else {
        item.content.map((paragraph, i) => {
          if (paragraph.marks === undefined) {
            paragraphString.push(paragraph.text);
          } else {
            let mark = paragraph.marks[0];
            let markedString = '';
            const markItem = schema.find((x) => x.id === mark.type);
            if (markItem && markItem !== undefined) {
              if (!markItem) {
                console.error('No mark item found in schema:', { mark, markItem, schema });
              } else if (markItem.type === 'wrap') {
                let sectionString = markWrap(paragraph.text, markItem);

                markedString = sectionString;
              } else if (markItem.type === 'function') {
                if (markItem.return === 'link') {
                  let link = linkParse(paragraph, mark);

                  markedString = link;
                }

                if (markItem.id === 'textStyle') {
                  const html = markItem.handler && markItem.handler(mark, paragraph.text)
                  if (html) markedString = html
                }
              } else {
                console.error('Unknown mark return type:', { mark, markItem });
              }
            } else {
              markedString = paragraph.text;
            }
            if (paragraph.marks.length > 1) {
              paragraph.marks.slice(1).map((mark) => {
                const markItem = schema.find((x) => x.id === mark.type);
                if (markItem && markItem !== undefined) {
                  if (!markItem) {
                    console.error('No mark item found in schema:', { mark, markItem, schema });
                  } else if (markItem.type === 'wrap') {
                    let sectionString = markWrap(markedString, markItem);
                    markedString = sectionString;
                  } else if (markItem.type === 'function') {
                    if (markItem.return === 'link') {
                      let link = linkParse(markedString, mark);
                      markedString = link;
                    }
                    if (markItem.id === 'textStyle') {
                      const html = markItem.handler && markItem.handler(mark, paragraph.text)
                      if (html) markedString = html
                    }
                  } else {
                    console.error('Unknown mark return type:', { mark, markItem });
                  }
                } else {
                  markedString = paragraph.text;
                }
              });
            }
            paragraphString.push(markedString);
          }
        });
      }
      let paragraph = '<p>' + paragraphString.join('') + '</p>';
      htmlString.push(paragraph);
    } else if (item.type === 'bullet_list' || item.type === 'ordered_list') {
      let bulletString = [];
      item.content.map((bullet_point) => {
        bullet_point.content.map((bullet_content) => {
          if (bullet_content.content === undefined) {
            bulletString.push('<li></li>');
          } else {
            bullet_content.content.map((bullet_item) => {
              if (bullet_item.marks === undefined) {
                let bulletItem = '<li>' + bullet_item.text + '</li>';
                bulletString.push(bulletItem);
              } else {
                bullet_item.marks.map((mark) => {
                  const markItem = schema.find((x) => x.id === mark.type);
                  if (!markItem) {
                    console.error('No mark item found in schema:', { mark, markItem, schema });
                  } else if (markItem.type === 'wrap') {
                    let sectionString =
                      '<' +
                      markItem.return +
                      '>' +
                      bullet_item.text +
                      '</' +
                      markItem.return +
                      '>';
                    let bulletItem = '<li>' + sectionString + '</li>';
                    bulletString.push(bulletItem);
                  } else if (markItem.type === 'function') {
                    if (markItem.return === 'link') {
                      let link = linkParse(bullet_item, mark);
                      bulletString.push(link);
                    }
                    if (markItem.id === 'textStyle') {
                      const html = markItem.handler && markItem.handler(mark, bullet_item.text, 'li')
                      if (html) bulletString.push(html)
                    }
                  } else {
                    console.error('Unknown mark return type:', { mark, markItem });
                  }
                });
              }
            });
          }
        });
      });
      let bulletPoints;
      if (item.type === 'ordered_list') {
        bulletPoints = '<ol>' + bulletString.join('') + '</ol>';
      } else {
        bulletPoints = '<ul>' + bulletString.join('') + '</ul>';
      }
      htmlString.push(bulletPoints);
    } else if (item.type === 'blockquote') {
      let blockquoteString = [];
      item.content.map((i) => {
        i.content.map((paragraph) => {
          if (paragraph.marks === undefined) {
            blockquoteString.push(paragraph.text);
          } else {
            paragraph.marks.map((mark) => {
              const markItem = schema.find((x) => x.id === mark.type);
              if (!markItem) {
                console.error('No mark item found in schema:', { mark, markItem, schema });
              } else if (markItem.type === 'wrap') {
                let sectionString = markWrap(text.content, markItem);
                blockquoteString.push(sectionString);
              } else if (markItem.type === 'function') {
                if (markItem.return === 'link') {
                  let link = linkParse(paragraph, mark);
                  blockquoteString.push(link);
                }
                if (markItem.id === 'textStyle') {
                  const html = markItem.handler && markItem.handler(mark, paragraph.text)
                  if (html) blockquoteString.push(html)
                }
              } else {
                console.error('Unknown mark return type:', { mark, markItem });
              }
            });
          }
        });
      });
      let quoteString =
        '<p><blockquote>' + blockquoteString.join('') + '</blockquote></p>';
      htmlString.push(quoteString);
    }
  });
  return htmlString.join('');
}
