import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { flex, height, maxWidth, space, width } from 'styled-system';

import NonStretchedImage from './non-stretched-image';

const imageStyles = css`
  ${height};
  ${width};
  ${space};
  ${maxWidth};
  ${flex};

  ${(props) =>
    props.asBackground &&
    css`
      position: absolute !important;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `};
`;

const Img = styled.img`
  ${imageStyles};
  display: block;
  width: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  ${width};
`;

const StyledNonStretchedImage = styled(NonStretchedImage)`
  ${imageStyles};
`;

const LazyImage = React.forwardRef((props, ref) => {
  const { url, fluid, childImageSharp, ...rest } = props;

  if (url) {
    return <Img src={url} {...rest} ref={ref} lazy />;
  }

  const fluidProps = useMemo(() => {
    return fluid || (childImageSharp && childImageSharp.fluid);
  }, [fluid, childImageSharp]);

  if (!fluidProps || typeof fluidProps == 'string') {
    return null;
  }

  return (
    <StyledNonStretchedImage
      placeholderStyle={{
        transition: 'opacity 0.65s ease-in 0.15s',
        filter: 'blur(10px)',
        left: '-2.5%',
        top: '-2.5%',
        width: '105%',
        height: '105%',
      }}
      imgStyle={{
        transition: 'opacity 0.55s ease-out',
      }}
      {...rest}
      fluid={fluidProps}
      ref={ref}
    />
  );
});

LazyImage.propTypes = {
  fluidProps: PropTypes.object,
  childImageSharp: PropTypes.object,
};

export default LazyImage;
