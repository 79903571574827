export const MOCKUP_WIDTH = 1440;
export const OFFSET_SIDE = 40;
export const CONTAINER_WIDTH = 1092;
export const CONTAINER_WIDTH_LARGE = 1240;
export const OFFSET_SIDE_SM = 20;
export const OFFSET_SIDE_XSM = 20;

export const BREAKPOINT_LG = 1179;
export const BREAKPOINT_MD = 991;
export const BREAKPOINT_SM = 767;
export const BREAKPOINT_XS = 558;
export const BREAKPOINT_XXS = 360;

export const MOBILE_MENU_HEIGHT = 50;

export const IS_BROWSER = typeof window !== 'undefined';

export const IS_IE11 =
  IS_BROWSER && !!window.MSInputMethodContext && !!document.documentMode;

export const EMAIL_CONTACT = 'hello@sertus.uk';

export const SUPPORTED_FORMATS = ['jpg', 'png', 'jpeg', 'pdf', 'cad'];

export const MAX_FILE_MB = 10;
export const MAX_FILE_SIZE = MAX_FILE_MB * 1024 * 1024;

export const STANDARD_PADDING = [50, 60, 75];

export const FILTER_ALL = { name: 'All', slug: 'all' };

export const HEADER_HEIGHT = 88;

export const NULL_LINK = '#';

export const CAD_FILE_TYPE = 'cad';

export const YES_NO_TYPE = 'yesNo';
