import { graphql, useStaticQuery } from 'gatsby';

import { parseStoryblokContent } from './parse-storyblok-content';

export function useGlobalCtaBlockData() {
  const { globalCtaBlock } = useStaticQuery(graphql`
    query {
      globalCtaBlock: storyblokEntry(
        field_component: { eq: "globalCtaBlock" }
      ) {
        id
        name
        uuid
        field_component
        content
      }
    }
  `);

  return parseStoryblokContent(globalCtaBlock).link[0];
}
