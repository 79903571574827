import { graphql, useStaticQuery } from 'gatsby';

import { parseStoryblokContent } from './parse-storyblok-content';

export function useMobileNavigation() {
  const { navigation: mobileNavigationData } = useStaticQuery(graphql`
    query {
      navigation: storyblokEntry(field_component: { eq: "mobileNavigation" }) {
        id
        name
        uuid
        field_component
        content
      }
    }
  `);

  return parseStoryblokContent(mobileNavigationData);
}
