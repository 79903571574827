import { getPath } from './paths';
import humps from 'humps';

import { FILTER_ALL, IS_BROWSER } from '../constants';
import getFluidGatsbyImage from './gatsby-storyblok-image-fixed/getFluidGatsbyImage';
import { useMemo } from 'react';

export function createMailTo(email, { subject } = {}) {
  const params = new URLSearchParams()
  if (subject) params.set('subject', subject)

  return `mailto:${email}?${params.toString()}`;
}

export function createAboutPageLinks(current) {
  return [
    {
      to: getPath.about(),
      children: 'Overview',
    },
    {
      to: getPath.team(),
      children: 'Our Team',
    },
    {
      to: getPath.howWeWork(),
      children: 'How we work',
    },
    {
      to: getPath.accreditations(),
      children: 'Accreditations',
    },
    {
      to: getPath.corporateSocialResponsibility(),
      children: 'CSR',
    },
  ].map((v) => {
    return v.to === current ? { ...v, active: true } : v;
  });
}

export function checkIfInvalidField(form, field) {
  return form.errors[field.name] && form.touched[field.name];
}

export function renderStringArray(arr, transformerFunc) {
  if (typeof arr == 'string') {
    return arr;
  }
  if (!Array.isArray(arr)) {
    return '';
  }
  return transformerFunc(arr);
}

const HUBSPOT_FORM_API = 'https://js.hsforms.net/forms/v2.js';

function hasHubSpotScriptOnPage() {
  return !!document.querySelector(`script[src="${HUBSPOT_FORM_API}"]`);
}

function addHubSpotFormScript(callback) {
  if (hasHubSpotScriptOnPage()) {
    callback();
    return;
  }

  const script = document.createElement('script');
  script.src = HUBSPOT_FORM_API;
  script.async = true;
  script.onload = callback;
  document.body.appendChild(script);
}

export function createHubSpotForm({ portalId, formId, targetId, ...rest }) {
  function create() {
    window.jQuery =
      window.jQuery ||
      (() => ({
        // these are all methods required by HubSpot
        change: () => {},
        trigger: () => {},
      }));
    window.hbspt.forms.create({
      portalId,
      formId,
      target: `#${targetId}`,
      ...rest,
    });
  }

  addHubSpotFormScript(create);
}

function getCategoryPropsFromRawString(categoryStr) {
  return {
    name: categoryStr,
    slug: humps.camelize(categoryStr),
  };
}

export function getDistinctCategoriesFromItems(items) {
  return items.edges.reduce(
    (acc, curr) => {
      const { node } = curr;
      const content = JSON.parse(node.content);
      const { category } = content;
      const isString = typeof category == 'string';
      function alreadyExists(v) {
        return isString ? v.name === category : v.slug === category.slug;
      }
      if (!acc.find(alreadyExists)) {
        acc = [
          ...acc,
          isString ? getCategoryPropsFromRawString(category) : category,
        ];
      }

      return acc;
    },
    [FILTER_ALL]
  );
}

export function openChat() {
  if (!IS_BROWSER || !window.HubSpotConversations) {
    return;
  }
  window.HubSpotConversations.widget.open();
}

export function menuItemIsUnderlined(isActive, index, itemHoverIndex) {
  if (itemHoverIndex != null) {
    return itemHoverIndex === index;
  }
  return isActive;
}

export function parseStoryblokIcon(icon) {
  if (!icon) {
    return null;
  }

  const isSvg = icon.filename.endsWith('.svg');

  if (isSvg) {
    return icon.filename;
  }

  return getFluidGatsbyImage(icon, {
    maxWidth: 200,
  });
}

export function createDropdownFilterOptions(cmsItems) {
  return useMemo(() => {
    return [
      { name: FILTER_ALL.name, value: '' },
      ...cmsItems.map((v) => {
        return {
          name: v.node.name,
          value: v.node.name.toLowerCase(),
        };
      }),
    ];
  }, [cmsItems]);
}
