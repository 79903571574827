import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { Link as LinkGatsby } from 'gatsby';
import {
  alignSelf,
  color,
  display,
  fontSize,
  fontWeight,
  justifySelf,
  left,
  letterSpacing,
  lineHeight,
  maxWidth,
  position,
  space,
  textAlign,
  width,
} from 'styled-system';

import { buttonReset, fontFamilySans, hoverDefault } from '../utils/styles';
import { openChat } from '../utils/utils';

const styles = css`
  ${fontFamilySans};
  ${display};
  ${position};
  ${space};
  ${fontSize};
  ${fontWeight};
  ${lineHeight};
  ${width};
  ${maxWidth};
  ${alignSelf};
  ${justifySelf};
  ${textAlign};
  ${left};
  ${letterSpacing};
  color: inherit;
  text-decoration: inherit;
  ${color};

  ${(props) =>
    props.hover &&
    css`
      ${hoverDefault};
    `};
`;

const config = {
  shouldForwardProp: (prop, defaultValidatorFn) =>
    ![
      'isHomePage',
      'altStyling',
      'semiTransparent',
      'whiteTransparent',
      'bigPadding',
      'borderColorPrimary',
      'borderPrimaryColorPrimary',
      'hover',
      'small',
      'active',
      'invalid',
      'transparent',
      'white',
      'colorPrimary',
    ].includes(prop),
};

const ButtonLink = styled.button.withConfig(config)`
  ${buttonReset};
  ${styles};
`;

const ExternalLink = styled.a.withConfig(config)`
  ${styles};
`;

const StyledLinkGatsby = styled(LinkGatsby).withConfig(config)`
  ${styles};
`;

function Link(props) {
  const isButton = !props.to;

  function handleClick(e) {
    if (!props.to || !props.to.endsWith('#chat')) {
      return;
    }
    e.preventDefault();
    openChat();
  }

  if (isButton) {
    return <ButtonLink {...props} />;
  }

  const isExternal = useMemo(() => {
    const externalLinkRegexp = new RegExp('^((tel|https?|mailto):|#).*$');
    return externalLinkRegexp.test(props.to);
  }, [props.to]);

  if (isExternal) {
    const { to, ...rest } = props;
    return <ExternalLink href={to} onClick={handleClick} {...rest} />;
  }

  return (
    <StyledLinkGatsby {...props} to={props.to === '/home' ? '/' : props.to} />
  );
}

export default Link;
