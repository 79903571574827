import { graphql, useStaticQuery } from 'gatsby';

import { parseStoryblokContent } from './parse-storyblok-content';
import { useMemo } from 'react';
import parseStoryblokLink from '../utils/parse-storyblok-link';

export function useNavigation() {
  const { navigation: globalNavigationData } = useStaticQuery(graphql`
    query {
      navigation: storyblokEntry(field_component: { eq: "global_navigation" }) {
        id
        name
        uuid
        field_component
        content
      }
    }
  `);

  return useMemo(() => {
    const content = JSON.parse(globalNavigationData.content);
    const transformerFunc = (v) => ({
      ...v,
      to: parseStoryblokLink(v.link),
    });
    return {
      ...content,
      items_left: content.items_left.map(transformerFunc),
      items_right: content.items_right.map(transformerFunc),
      items_top_bar: content.items_top_bar.map(transformerFunc),
    };
  }, [globalNavigationData]);
}
