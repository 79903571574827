import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { Sticky } from 'react-sticky';

import { Box, Flex } from '../UIKit/system';
import Container from '../container';
import Logo from '../../images/svg/logo.svg';
import NavItem from './nav-item';
import LogoLink from '../logo-link';
import mq from '../../utils/mq';
import { getThemeColor } from '../../utils/styles';
import Burger from '../burger';
import Button from '../button';
import MobileNavigation from './mobile-navigation';
import { withResponsiveContext } from '../responsive';
import { useNavigation } from '../../hooks/use-navigation';
import { useMobileNavigation } from '../../hooks/use-mobile-navigation';
import { getPath } from '../../utils/paths';

const StyledNavigation = styled.nav`
  width: 100%;
  max-width: 100vw;
  margin-top: 48px;
  position: sticky;
  top: 0;
  z-index: 125;
  user-select: none;

  ${mq.tablet(css`
    margin-top: 0;
  `)};
`;

const TopBar = styled(Flex)`
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 122;
  user-select: none;

  ${mq.tablet(css`
    display: none;
  `)};
`;

const TopLink = styled(Link)`
  color: #959595;
  font-size: 13px;
  line-height: 16px;
  margin-right: 20px;
  text-decoration: none;

  &:last-of-type {
    margin-right: 0;
  }
`;

const NavBar = styled(Box)`
  z-index: 120;
  width: 100%;
`;

const NavBarInner = styled(Box)`
  border-bottom-width: 0px;
  ${mq.tablet(css`
    background-color: ${getThemeColor('black')};
    margin-top: 0;
  `)};
`;

const StyledLogo = styled(Logo)`
  fill: #000;

  ${mq.tablet(css`
    fill: #fff;
  `)};

  ${mq.mobile(css`
    position: relative;
    top: 1px;
    width: 54px;
  `)};
`;

const Menu = styled(Flex)`
  ${mq.tablet(css`
    display: none;
  `)};
`;

const ButtonContact = styled(Button)`
  display: none;

  ${mq.tablet(css`
    display: flex;
  `)};
`;

const NavigationInnerComponent = React.forwardRef(
  (
    { navbarStyles, items_left, items_right, editorContent, setShowMobileMenu },
    ref
  ) => {
    const [currentNavItemHover, setCurrentNavItemHover] = useState('');

    function renderNavItem(key, item) {
      return (
        <NavItem
          key={key}
          isEditorContent={!!editorContent}
          dropdownIsVisible={currentNavItemHover === item.children}
          onSetDropdownName={(name) => setCurrentNavItemHover(name)}
          {...item}
        />
      );
    }

    return (
      <NavBar
        position={'relative'}
        style={navbarStyles}
        ref={ref}
        onMouseLeave={() => setCurrentNavItemHover('')}
      >
        <NavBarInner
          position={'relative'}
          zIndex={2}
          borderBottomWidth={[0, 0, 1]}
          borderBottomStyle={'solid'}
          borderBottomColor={'#ddd'}
          bg={'#fff'}
        >
          <Container large>
            <Flex
              as={'nav'}
              height={[50, 80, 88]}
              alignItems={'center'}
              justifyContent={['space-between', null, 'flex-start']}
            >
              <Box mr={60}>
                <LogoLink>
                  <StyledLogo />
                </LogoLink>
              </Box>
              <Menu flex={1} justifyContent={'space-between'}>
                <Flex>
                  {items_left.map((item, index) => {
                    return renderNavItem(index, item);
                  })}
                </Flex>
                <Flex>
                  {items_right.map((item, index) => {
                    return renderNavItem(index, item);
                  })}
                </Flex>
              </Menu>
              <Flex alignItems={'center'}>
                <ButtonContact
                  to={getPath.contact()}
                  mr={[20, 34]}
                  px={17}
                  height={[28, 34]}
                >
                  Contact
                </ButtonContact>
                <div onClick={() => setShowMobileMenu(true)}>
                  <Burger />
                </div>
              </Flex>
            </Flex>
          </Container>
        </NavBarInner>
        <div id="nav-modal-container" />
      </NavBar>
    );
  }
);

NavigationInnerComponent.displayName = 'NavigationInnerComponent';

const Navigation = ({ isMobileOrTablet, editorContent }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const cmsContent = useNavigation();
  const mobileNavigationData = useMobileNavigation();

  const { items_top_bar, items_left, items_right } =
    editorContent || cmsContent;

  function handleCloseMenu() {
    setShowMobileMenu(false);
  }

  useEffect(() => {
    if (!isMobileOrTablet) {
      setShowMobileMenu(false);
    }
  }, [isMobileOrTablet]);

  return (
    <React.Fragment>
      <TopBar
        position={'relative'}
        zIndex={2}
        height={48}
        width={'100%'}
        backgroundColor={'#161616'}
      >
        <Container large>
          <Flex height={48} justifyContent={'flex-end'} alignItems={'center'}>
            {items_top_bar.map((item, index) => {
              return <TopLink key={index} {...item} />;
            })}
          </Flex>
        </Container>
      </TopBar>

      <StyledNavigation>
        <MobileNavigation
          show={showMobileMenu}
          onClose={handleCloseMenu}
          data={mobileNavigationData}
        />
        <Sticky
          topOffset={isMobileOrTablet ? 0 : 50}
          disableHardwareAcceleration
        >
          {({ style: stickyStyles }) => {
            return (
              <NavigationInnerComponent
                navbarStyles={stickyStyles}
                items_top_bar={items_top_bar}
                items_left={items_left}
                items_right={items_right}
                editorContent={editorContent}
                setShowMobileMenu={setShowMobileMenu}
              />
            );
          }}
        </Sticky>
      </StyledNavigation>
    </React.Fragment>
  );
};

export default withResponsiveContext(Navigation);
