import React from 'react';
import styled from 'styled-components';
import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import { Box, Flex } from './UIKit/system';
import Container from './container';
import SectionTitle from './section-title';
import SectionSubtitle from './section-subtitle';
import Button from './button';
import { withResponsiveContext } from './responsive';
import LazyImage from './lazy-image';

const StyledSectionCTA = styled(Box)`
  position: relative;
  overflow: hidden;

  .parallax-outer,
  .parallax-inner {
    height: 100%;
    width: 100%;
  }

  .parallax-outer {
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const BackgroundImage = styled(LazyImage)`
  position: absolute !important;
  left: 0;
  right: 0;
  top: -30px;
  bottom: -30px;
`;

function SectionCTA({
  desktopBackground,
  mobileBackground,
  ctaLink,
  ctaText,
  subtitle,
  title,
  isDesktopOrTablet,
}) {
  return (
    <StyledSectionCTA
      as={'section'}
      py={[156]}
      backgroundColor={'#161616'}
      color={'white'}
      backgroundSize="cover"
      backgroundPosition="center"
    >
      <ParallaxProvider>
        <Parallax y={[-8, 8]}>
          <BackgroundImage
            fluid={isDesktopOrTablet ? desktopBackground : mobileBackground}
          />
        </Parallax>
      </ParallaxProvider>
      <Container relative>
        <Flex
          justifyContent={'center'}
          flexDirection={'column'}
          alignItems={'center'}
        >
          <SectionTitle mb={[15]} fontSize={[36, 28, 38, 42]}>
            {title}
          </SectionTitle>
          <SectionSubtitle
            mb={[48]}
            fontSize={[18, 24, 24, 24]}
            textAlign={'center'}
          >
            {subtitle}
          </SectionSubtitle>
          <Button to={ctaLink} bigPadding inDarkBlock={true}>
            {ctaText}
          </Button>
        </Flex>
      </Container>
    </StyledSectionCTA>
  );
}

export default withResponsiveContext(SectionCTA);
