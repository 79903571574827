import React, { useContext } from 'react';
import throttle from 'lodash/throttle';
import isEqual from 'lodash/isEqual';

import { BREAKPOINT_MD, BREAKPOINT_SM, BREAKPOINT_XXS } from '../constants';

export const ResponsiveContext = React.createContext({ isMobile: false });

function Responsive({ children }) {
  return (
    <ResponsiveContext.Consumer>
      {(context) => children(context)}
    </ResponsiveContext.Consumer>
  );
}

export function withResponsiveContext(WrappedComponent) {
  return function WithResponsiveContext(props) {
    return (
      <Responsive>
        {(context) => <WrappedComponent {...props} {...context} />}
      </Responsive>
    );
  };
}

class ResponsiveProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};

    if (typeof window === 'undefined') {
      this.state = {
        ...this.state,
        context: {
          isMobileSmall: false,
          isMobile: false,
          isTablet: false,
          isDesktop: true,
        },
      };
    } else {
      this.state = {
        ...this.state,
        context: this.getContext(),
      };
    }
  }

  handleWindowResize = throttle(() => {
    this.update();
  }, 200);

  componentDidMount() {
    this.update();
    window.addEventListener('resize', this.handleWindowResize);
  }

  shouldComponentUpdate(prevProps, prevState) {
    if (
      prevState.context !== this.state.context &&
      isEqual(prevState.context, this.state.context)
    ) {
      return false;
    }
    return true;
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  getContext() {
    const windowWidth = window.innerWidth;

    let newContext = {
      isDesktop: false,
      isDesktopOrTablet: false,
      isTablet: false,
      isMobile: false,
      isMobileSmall: false,
      isMobileAll: false,
    };

    if (windowWidth <= BREAKPOINT_XXS) {
      newContext = {
        ...newContext,
        isMobileSmall: true,
        isMobileAll: true,
      };
    } else if (windowWidth <= BREAKPOINT_SM) {
      newContext = {
        ...newContext,
        isMobile: true,
        isMobileAll: true,
      };
    } else if (windowWidth <= BREAKPOINT_MD) {
      newContext = {
        ...newContext,
        isTablet: true,
      };
    } else {
      newContext = {
        ...newContext,
        isDesktop: true,
      };
    }

    if (newContext.isDesktop || newContext.isTablet) {
      newContext.isDesktopOrTablet = true;
    }

    newContext.isMobileOrTablet =
      newContext.isMobileSmall || newContext.isMobile || newContext.isTablet;

    return newContext;
  }

  update() {
    this.setState({
      context: this.getContext(),
    });
  }

  render() {
    return (
      <ResponsiveContext.Provider value={this.state.context}>
        {this.props.children}
      </ResponsiveContext.Provider>
    );
  }
}

function MobileSmall({ children }) {
  return (
    <ResponsiveContext.Consumer>
      {({ isMobileSmall }) => isMobileSmall && children}
    </ResponsiveContext.Consumer>
  );
}

function Mobile({ children }) {
  return (
    <ResponsiveContext.Consumer>
      {({ isMobile, isMobileSmall }) => (isMobile || isMobileSmall) && children}
    </ResponsiveContext.Consumer>
  );
}

function NotMobile({ children }) {
  return (
    <ResponsiveContext.Consumer>
      {({ isMobile, isMobileSmall }) =>
        !(isMobile && isMobileSmall) && children
      }
    </ResponsiveContext.Consumer>
  );
}

function MobileOrTablet({ children }) {
  return (
    <ResponsiveContext.Consumer>
      {({ isMobileOrTablet }) => isMobileOrTablet && children}
    </ResponsiveContext.Consumer>
  );
}

const Tablet = MobileOrTablet;

function Desktop({ children }) {
  return (
    <ResponsiveContext.Consumer>
      {({ isDesktop }) => isDesktop && children}
    </ResponsiveContext.Consumer>
  );
}

function DesktopOrTablet({ children }) {
  return (
    <ResponsiveContext.Consumer>
      {({ isDesktopOrTablet }) => isDesktopOrTablet && children}
    </ResponsiveContext.Consumer>
  );
}

export function useResponsiveContext() {
  return useContext(ResponsiveContext);
}

Responsive.MobileSmall = MobileSmall;
Responsive.Mobile = Mobile;
Responsive.NotMobile = NotMobile;
Responsive.MobileOrTablet = MobileOrTablet;
Responsive.Tablet = Tablet;
Responsive.Desktop = Desktop;
Responsive.DesktopOrTablet = DesktopOrTablet;

Responsive.Provider = ResponsiveProvider;

export default Responsive;
