export const getPath = {
  home: () => '/',
  about: () => '/about-us/',
  team: () => '/team/',
  howWeWork: () => '/how-we-work/',
  accreditations: () => '/accreditations/',
  corporateSocialResponsibility: () => '/corporate-social-responsibility/',
  contact: () => '/contact/',
  contactGeneralQuestions: () => '/contact/general-questions/',
  contactStartProject: () => '/contact/start-project/',
  contactSiteSupport: () => '/contact/site-support',
  contactBookCPD: () => '/contact/book-a-cpd',
  caseStudies: () => '/case-studies/',
  caseStudy: () => '/case-study/',
  products: () => '/products/',
  productV1: () => '/product-v1/',
  productV2: () => '/product-v2/',
  support: () => '/support/',
  systems: () => '/systems-1/',
  blog: () => '/blog/',
  courses: () => '/courses/',
  downloads: ({ category } = {}) =>
    `/downloads/${category ? `category/${category}` : ''}`,
  specTool: () => '/project-specification-tool/',
  specToolCalculation: () => '/project-specification-calculation/',
  cadViewer: ({ slug }) => `/cad-viewer/${slug}`,
};
