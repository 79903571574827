import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import gsap, { Power0, Power1 } from 'gsap';

import Container from '../container';
import DropdownPortal from './dropdown-portal';
import { useOutsideClick } from '../../hooks/use-outside-click';
import {
  Column,
  ColumnContent,
  ColumnHeader,
  Columns,
  ColumnsContainer,
  ColumnTitle,
  Icon,
  IconContainer,
  IconHover,
  LinkContainer,
  LinkGroups,
  LinkGroupTitle,
  StyledIconicColumnLink,
  StyledLink,
} from './shared-components';
import ShortcutLink from './shortcut-link';
import { Box, Flex, Paragraph } from '../UIKit/system';
import { getFluidGatsbyImage } from '../../utils/gatsby-storyblok-image-fixed';
import Button from '../button';
import LinkWithArrow from '../link-with-arrow';
import RichtextParser from '../../utils/richtext-parser';
import parseStoryblokLink from '../../utils/parse-storyblok-link';

const StyledDropdown = styled.div`
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  padding: 40px 0;
  background-color: #fff;
  z-index: 1;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.08), 0px -1px 8px rgba(0, 0, 0, 0.1);
  transition: opacity 0.15s ease-out;
  opacity: 0;
  visibility: hidden;
  will-change: transform;

  [data-animated] {
    will-change: transform;
  }
`;

const Dropdown = ({ show, content, ...rest }) => {
  const [modalNode, setModalNode] = useState(null);
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
      return;
    }
    show ? animateShow() : animateHide();
  }, [show, modalNode]);

  const {
    gridTemplateColumns,
    columnGap,
    linkColumns,
    iconicColumns,
    featuredLinks,
    buttonLink,
    buttonText,
    additionalText,
  } = content || {};

  function animateShow() {
    gsap.fromTo(
      modalNode,
      0.1,
      { autoAlpha: 0, y: 0 },
      { autoAlpha: 1, ease: Power1.easeOut }
    );
  }

  function animateHide() {
    gsap.to(modalNode, 0.1, {
      autoAlpha: 0,
      ease: Power0.easeNone,
    });
  }

  function renderColumns() {
    let innerJsx;

    if (linkColumns && linkColumns.length > 0) {
      innerJsx = linkColumns.map((column, index) => {
        return (
          <Column key={index} data-animated>
            <ColumnHeader>{column.title}</ColumnHeader>
            <ColumnContent justifyContent={'space-between'}>
              <LinkGroups
                key={index}
                gridTemplateColumns={column.gridTemplateColumns}
              >
                {column.linkGroups &&
                  column.linkGroups.length > 0 &&
                  column.linkGroups.map((linkGroup, index) => {
                    if (!linkGroup.mobileOnly) {
                      return (
                        <Box key={index}>
                          {linkGroup.title && (
                            <LinkGroupTitle
                              to={parseStoryblokLink(linkGroup.titleLink)}
                            >
                              {linkGroup.title}
                            </LinkGroupTitle>
                          )}
                          <LinkContainer>
                            {linkGroup.links &&
                              linkGroup.links.length > 0 &&
                              linkGroup.links.map(
                                ({ name, link, mobileOnly }, index) => {
                                  if (!mobileOnly) {
                                    return (
                                      <StyledLink
                                        key={index}
                                        to={link && parseStoryblokLink(link)}
                                      >
                                        {name}
                                      </StyledLink>
                                    );
                                  }
                                }
                              )}
                          </LinkContainer>
                        </Box>
                      );
                    }
                  })}
              </LinkGroups>
            </ColumnContent>
          </Column>
        );
      });
    } else if (iconicColumns && iconicColumns.length > 0) {
      innerJsx = iconicColumns.map((column, index) => {
        const parsedLink = parseStoryblokLink(column.link);
        const innerJsx = (
          <Column
            key={index}
            data-animated
            iconic
            alternativeStyling={column.alternativeStyling}
          >
            {column.icon && (
              <IconContainer width={'100%'}>
                <Icon
                  src={column.icon.filename}
                  alternativeStyling={column.alternativeStyling}
                />
                {column.iconHover && (
                  <IconHover
                    src={column.iconHover.filename}
                    alternativeStyling={column.alternativeStyling}
                  />
                )}
              </IconContainer>
            )}
            <div>
              <ColumnTitle noMarginBottom={column.alternativeStyling}>
                {column.title}
              </ColumnTitle>
              <Paragraph letterSpacing={0} grey fontSizeExtraSmall>
                {column.text}
              </Paragraph>
            </div>
            {!column.alternativeStyling && (
              <LinkWithArrow to={column.link && parsedLink} mt={10} small>
                Learn more
              </LinkWithArrow>
            )}
          </Column>
        );
        return column.alternativeStyling ? (
          <StyledIconicColumnLink
            key={index}
            to={parsedLink}
            hoverStateSupport={column.iconHover && column.iconHover.filename}
          >
            {innerJsx}
          </StyledIconicColumnLink>
        ) : (
          innerJsx
        );
      });
    }

    const parsedButtonLink = parseStoryblokLink(buttonLink);
    const buttonJsx = parsedButtonLink && buttonText && (
      <Flex>
        <Button
          to={parsedButtonLink}
          mt={33}
          letterSpacing={0}
          borderPrimaryColorPrimary
          small
        >
          {buttonText}
        </Button>
      </Flex>
    );

    return (
      <ColumnsContainer>
        <Columns
          columnNumber={(linkColumns || iconicColumns).length}
          gridTemplateColumns={gridTemplateColumns}
          columnGap={columnGap}
        >
          {innerJsx}
        </Columns>
        {buttonJsx}
      </ColumnsContainer>
    );
  }

  return (
    <DropdownPortal>
      <StyledDropdown {...rest} ref={(node) => setModalNode(node)}>
        <Container large>
          <Flex justifyContent={'space-between'}>
            {renderColumns()}
            {featuredLinks && featuredLinks.length > 0 && (
              <Flex alignItems={'flex-start'}>
                {featuredLinks.map((item, index) => {
                  return (
                    <ShortcutLink
                      key={index}
                      to={parseStoryblokLink(item.link)}
                      title={item.title}
                      subtitle={item.subtitle}
                      image={getFluidGatsbyImage(item.backgroundImage, {
                        maxWidth: 210,
                      })}
                      data-animated
                    />
                  );
                })}
              </Flex>
            )}
            {additionalText && (
              <Paragraph
                maxWidth={200}
                textAlign={'right'}
                html={RichtextParser(additionalText)}
                smallMargin
                fontSizeExtraSmall
                data-animated
                grey
              />
            )}
          </Flex>
        </Container>
      </StyledDropdown>
    </DropdownPortal>
  );
};

export default Dropdown;
