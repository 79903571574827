import React, { useMemo } from 'react';
import { getThemeColor } from '../utils/styles';
import styled, { css } from 'styled-components';

import { Box, Flex } from './UIKit/system';
import logoBoxImage from '../images/logo-box.png';
import Container from './container';
import Link from './link';
import mq from '../utils/mq';
import { useFooter } from '../hooks/use-footer';
import parseStoryblokLink from '../utils/parse-storyblok-link';
import { useSocialLinks } from '../hooks/use-social-links';
import { useContactData } from '../hooks/use-contact-data';

const StyledFooter = styled(Box)``;

const OuterContent = styled(Flex)`
  justify-content: space-between;

  ${mq.mobile(css`
    flex-direction: column;
    justify-content: center;
  `)};
`;

const LinkContainerOuter = styled(Box)`
  ${mq.tablet(css`
    width: 100%;
  `)};
  ${mq.mobile(css`
    display: none;
  `)};
`;

const LogoContainerOuter = styled(Box)`
  ${mq.tablet(css`
    display: none;
  `)};

  ${mq.mobile(css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  `)};
`;

const LogoImage = styled.img`
  width: 110px;
  ${mq.tablet(css`
    display: none;
  `)};
  ${mq.mobile(css`
    display: block;
    width: 74px;
  `)};
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 792px;
`;

const RowCollection = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -40px;
`;

const LinkCollection = styled.div`
  margin-bottom: 40px;
  width: 25%;

  ${(props) =>
    props.twoColumns &&
    css`
      width: 50%;
    `};
`;

const LinkTitle = styled.div`
  font-weight: bold;
  font-size: 15px;
  line-height: 19px;
  color: ${getThemeColor('purple')};
  height: 40px;
  letter-spacing: 0;
`;

const Links = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  ${(props) =>
    props.twoColumns &&
    css`
      flex-direction: row;

      > * {
        width: 50%;
      }
    `};

  ${mq.tablet(css`
    width: 100%;
  `)};
`;

const StyledLink = styled(Link)`
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  cursor: pointer;
  transition: 300ms;
  text-decoration: none;
  letter-spacing: 0;

  :hover {
    transition: 300ms;
    color: ${getThemeColor('purple')};
  }
`;

const StyledLinkItem = styled.div`
  display: flex;
`;

function LinkItem(props) {
  return (
    <StyledLinkItem>
      <StyledLink {...props} />
    </StyledLinkItem>
  );
}

const LinkItemLocation = styled.div`
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  padding-top: 7.5px;
  padding-bottom: 23px;
`;

const LinkItemPhone = styled.a`
  font-size: 14px;
  line-height: 18px;
  color: #666666;
  padding-top: 7.5px;
  padding-bottom: 23px;
  transition: 300ms;
  cursor: pointer;
  :hover {
    transition: 300ms;
    color: ${getThemeColor('purple')};
  }
`;

const CopyrightDivider = styled.div`
  border: 0px;
  border-top: 1px solid #dddddd;
  margin-top: 50px;
  margin-bottom: 25px;
  ${mq.mobile(css`
    margin-top: 35px;
  `)};
`;

const CopyrightText = styled.div`
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  color: #666666;
  letter-spacing: 0;
`;

const SocialIconsContainer = styled.div`
  display: flex;
  width: 46px;
  justify-content: space-between;
  margin-top: 15px;
`;

const SocialIcon = styled.div`
  width: 17px;
  height: 16px;
`;

const MobileLinkItems = styled.div`
  display: none;
  ${mq.mobile(css`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 35px;
  `)};
`;

const MobileLinkItem = styled(Link)`
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: #666666;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const MobileSocialLinks = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
`;

const MobileSocialLink = styled(Link)`
  width: 27px;
  height: 26px;
  margin-right: 20px;

  &:last-of-type {
    margin-right: 0;
  }
`;

const SocialIconMobile = styled.img`
  width: 27px;
  height: auto;
`;

function Footer({ footerEditorContent }) {
  const footerCmsData = useFooter();
  const footerData = footerEditorContent || footerCmsData;
  const socialLinksData = useSocialLinks();
  const contactData = useContactData();
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <StyledFooter pt={[32, 60, 75]} pb={25}>
      <Container large>
        <OuterContent>
          <LogoContainerOuter>
            <LogoImage src={logoBoxImage} />
          </LogoContainerOuter>
          <LinkContainerOuter>
            <LinkContainer>
              <RowCollection>
                {footerData.columns.map((column, index) => {
                  return (
                    <LinkCollection key={index} twoColumns={column.twoColumns}>
                      <LinkTitle>{column.title}</LinkTitle>
                      <Links twoColumns={column.twoColumns}>
                        {column.links &&
                          column.links.map((item, index) => {
                            return (
                              <LinkItem key={index} to={parseStoryblokLink(item.link)}>
                                {item.name}
                              </LinkItem>
                            );
                          })}
                      </Links>
                      {column.socialLinks && socialLinksData && (
                        <SocialIconsContainer>
                          {socialLinksData.items.map((item, index) => {
                            return (
                              <SocialIcon key={index}>
                                <Link
                                  to={parseStoryblokLink(item.link)}
                                  target="_blank"
                                >
                                  {item.icon && (
                                    <img
                                      src={item.icon.filename}
                                      aria-hidden="true"
                                      alt="Icon"
                                    />
                                  )}
                                </Link>
                              </SocialIcon>
                            );
                          })}
                        </SocialIconsContainer>
                      )}
                      {column.contactDetails && (
                        <div>
                          {contactData.address && (
                            <LinkItemLocation>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: contactData.address,
                                }}
                              />
                            </LinkItemLocation>
                          )}
                          {contactData.phone && (
                            <LinkItemPhone href={`tel:${contactData.phone}`}>
                              {contactData.phone}
                            </LinkItemPhone>
                          )}
                        </div>
                      )}
                    </LinkCollection>
                  );
                })}
              </RowCollection>
            </LinkContainer>
          </LinkContainerOuter>
          <MobileLinkItems>
            {footerData.mobileLinks &&
              footerData.mobileLinks.map((item, index) => {
                return (
                  <MobileLinkItem key={index} to={parseStoryblokLink(item.link)}>
                    {item.name}
                  </MobileLinkItem>
                );
              })}
            {socialLinksData && (
              <MobileSocialLinks>
                {socialLinksData.items.map((item, index) => {
                  return (
                    <MobileSocialLink
                      key={index}
                      to={parseStoryblokLink(item.link)}
                      target="_blank"
                    >
                      <SocialIconMobile
                        src={item.icon.filename}
                        aria-hidden="true"
                        alt="Icon"
                      />
                    </MobileSocialLink>
                  );
                })}
              </MobileSocialLinks>
            )}
          </MobileLinkItems>
        </OuterContent>
      </Container>
      <CopyrightDivider />
      {footerData.copyright && (
        <CopyrightText>
          {footerData.copyright.replace('{{ CURRENT_YEAR }}', currentYear)}
        </CopyrightText>
      )}
    </StyledFooter>
  );
}

export default Footer;
