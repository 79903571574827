import { graphql, useStaticQuery } from 'gatsby';

import { parseStoryblokContent } from './parse-storyblok-content';

export function useContactData() {
  const { contactData } = useStaticQuery(graphql`
    query {
      contactData: storyblokEntry(field_component: { eq: "contactData" }) {
        id
        name
        uuid
        field_component
        content
      }
    }
  `);

  return parseStoryblokContent(contactData);
}
