import React from 'react';

import SectionCTA from '../../section-cta';
import { getFluidGatsbyImage } from '../../../utils/gatsby-storyblok-image-fixed/';
import parseStoryblokLink from '../../../utils/parse-storyblok-link';

function StoryblokCtaBanner({
  backgroundImage,
  mobileBackgroundImage,
  ctaLink,
  ...rest
}) {
  let fluidImageDesktop = getFluidGatsbyImage(backgroundImage, {
    maxWidth: 2560,
  });
  let fluidImageMobile = getFluidGatsbyImage(mobileBackgroundImage, {
    maxWidth: 700,
  });

  return (
    <SectionCTA
      gatsbyImg
      desktopBackground={fluidImageDesktop}
      mobileBackground={fluidImageMobile}
      ctaLink={parseStoryblokLink(ctaLink)}
      {...rest}
    />
  );
}

export default StoryblokCtaBanner;
