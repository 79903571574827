import React from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

import Container from '../container';
import ArrowInCircle from '../arrow-in-circle';
import LazyImage from '../lazy-image';
import Chevron from '../../images/svg/chevron-right.svg';
import parseStoryblokLink from '../../utils/parse-storyblok-link';
import Link from '../link';
import getFluidGatsbyImage from '../../utils/gatsby-storyblok-image-fixed/getFluidGatsbyImage';
import RichTextField from '../rich-text-field';

const MobileNavigationContainer = styled(motion.div)`
  position: fixed;
  top: 70px;
  left: 0;
  height: calc(100% - 70px);
  width: 100%;
  color: #fff;
  z-index: 200;
  overflow-y: auto;
`;

const StyledContainer = styled(Container)`
  position: relative;
  width: 100%;
  background-color: #161616;
  padding-bottom: 60px;
`;

const MenuTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 46px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-bottom: 36px;
`;

const BackButton = styled(Chevron)`
  transform: scale(1.4) rotate(180deg);
  height: 12px;
  width: 6px;
`;

const TopSection = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  z-index: 180;
  font-size: 0;
  background-color: #161616;
`;

const MenuSectionContainer = styled.div`
  overflow-y: scroll;
`;

const MenuSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;
`;

const MenuTitleContainer = styled.div`
  position: sticky;
  top: 0;
  padding-top: 20px;
  z-index: 150;
  background-color: #161616;
`;

const MenuSectionTitle = styled.div`
  font-weight: bold;
  font-size: 17px;
  line-height: 46px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #959595;
  border-style: solid;
  border-width: 0;
  border-color: #333333;
  border-bottom-width: 1px;
  margin-bottom: 8px;
`;

const MenuItem = styled(Link)`
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.01em;

  margin-top: 16px;
  margin-bottom: 16px;
  text-align: left;
  ${(props) =>
    props.accent &&
    css`
      font-weight: bold;
    `};
`;

const MenuItemParagraphLink = styled(Link)`
  font-weight: bold;
  font-size: 17px;
  line-height: 46px;
  display: flex;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-top: 8px;
  margin-bottom: 8px;
  justify-content: space-between;
  align-items: center;
  border-style: solid;
  border-color: #333333;
  border-width: 0px;
  border-bottom-width: 1px;
  padding-bottom: 16px;
  text-align: left;

  :first-of-type {
    margin-top: 0px;
  }
  :last-of-type {
    border-bottom-width: 0px;
  }
`;

const MenuItemTextContainer = styled.div``;

const MenuItemParagraphText = styled.div`
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.01em;
  color: #959595;
  font-weight: normal;
  margin-top: -8px;
  max-width: 250px;
`;

const FeaturedLinks = styled.div`
  margin-top: 56px;
`;

const FeaturedLinkContainer = styled(Link)`
  display: block;
  width: 100%;
  height: 228px;
  position: relative;
  margin-bottom: 30px;

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const FeaturedLinkTextContainer = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 2;
  margin-left: 16px;
  margin-bottom: 16px;
`;

const FeaturedLinkSubtext = styled.div`
  font-size: 14px;
  letter-spacing: -0.02em;
  color: #ffffff;
`;

const FeaturedLinkTitle = styled.div`
  font-weight: 800;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: #ffffff;
`;

const FeaturedLinkImage = styled(LazyImage)`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
`;

const ContactSectionContainer = styled.div`
  font-size: 14px;
  letter-spacing: -0.01em;
  color: #959595;
  display: flex;
  flex-direction: column;
  margin-top: 56px;
  line-height: 1.4;

  a {
    display: block;
    color: currentColor;
    margin: 7px 0;
  }
`;

const pageAnim = {
  false: {
    x: '100%',
    transition: {
      duration: 0.2,
      ease: 'easeIn',
    },
  },

  true: {
    x: '0',
    transition: {
      duration: 0.275,
      ease: 'easeOut',
    },
  },
};

function MobileSubmenu({ show, name, content, sections, onClose, ...rest }) {
  function handleClose() {
    onClose();
  }

  function renderLinks() {
    return content.linkColumns.map((linkColumn, index) => {
      return (
        <MenuSection key={index}>
          {linkColumn.title && (
            <MenuSectionTitle>{linkColumn.title}</MenuSectionTitle>
          )}
          {linkColumn.linkGroups &&
            linkColumn.linkGroups.map((linkGroup) => {
              const parsedTitleLink = parseStoryblokLink(linkGroup.titleLink);
              const titleLink = parsedTitleLink && linkGroup.title && (
                <MenuItem accent key={index} to={parsedTitleLink}>
                  {linkGroup.title}
                </MenuItem>
              );

              const links = linkGroup.links.map((link, index) => {
                return (
                  <MenuItem key={index} to={parseStoryblokLink(link.link)}>
                    {link.name}
                  </MenuItem>
                );
              });
              return (
                <>
                  {titleLink}
                  {links}
                </>
              );
            })}
        </MenuSection>
      );
    });
  }

  function renderLargeLinks() {
    return content.iconicColumns.map((column, index) => {
      return (
        <MenuItemParagraphLink key={index} to={parseStoryblokLink(column.link)}>
          <MenuItemTextContainer>
            {column.title}
            <MenuItemParagraphText>{column.text}</MenuItemParagraphText>
          </MenuItemTextContainer>
          <ArrowInCircle white large />
        </MenuItemParagraphLink>
      );
    });
  }

  function renderFeaturedLinks() {
    return (
      <FeaturedLinks>
        {content.featuredLinks.map((link, index) => {
          return (
            <FeaturedLinkContainer
              key={index}
              to={parseStoryblokLink(link.link)}
            >
              <FeaturedLinkTextContainer>
                <FeaturedLinkSubtext>{link.subtitle}</FeaturedLinkSubtext>
                <FeaturedLinkTitle>{link.title}</FeaturedLinkTitle>
              </FeaturedLinkTextContainer>
              <FeaturedLinkImage
                fluid={getFluidGatsbyImage(link.backgroundImage, {
                  maxWidth: 440,
                })}
              />
            </FeaturedLinkContainer>
          );
        })}
      </FeaturedLinks>
    );
  }

  function renderAdditionalText() {
    return (
      <ContactSectionContainer>
        <RichTextField data={content.additionalText} />
      </ContactSectionContainer>
    );
  }

  return (
    <AnimatePresence>
      {show && (
        <MobileNavigationContainer
          key={'mobileSubmenuContainer'}
          initial="false"
          animate={show ? 'true' : 'false'}
          exit={'false'}
          variants={pageAnim}
        >
          <StyledContainer>
            <TopSection>
              <BackButton fill={'#fff'} onClick={handleClose} />
            </TopSection>
            <MenuTitleContainer>
              {name && <MenuTitle>{name}</MenuTitle>}
            </MenuTitleContainer>
            <MenuSectionContainer>
              {content.linkColumns && renderLinks()}
              {content.iconicColumns && renderLargeLinks()}
              {content.featuredLinks && renderFeaturedLinks()}
              {content.additionalText && renderAdditionalText()}
            </MenuSectionContainer>
          </StyledContainer>
        </MobileNavigationContainer>
      )}
    </AnimatePresence>
  );
}

export default MobileSubmenu;
