import React from 'react';
import styled, { css } from 'styled-components';
import {
  alignSelf,
  color,
  display,
  fontSize,
  fontWeight,
  justifySelf,
  left,
  letterSpacing,
  lineHeight,
  maxWidth,
  position,
  space,
  textAlign,
  width,
} from 'styled-system';

import { Text } from './UIKit/system';
import { getThemeColor, transitionDefault } from '../utils/styles';
import Link from './link';
import ArrowInCircle from './arrow-in-circle';

const StyledArrowInCircle = styled(ArrowInCircle)`
  margin-right: 8px;
  flex-shrink: 0;
`;

const LinkWithArrowContainer = styled(Link)`
  ${transitionDefault};
  ${display};
  ${position}
  ${space}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${width};
  ${maxWidth};
  ${alignSelf};
  ${justifySelf};
  ${textAlign};
  ${left};
  ${letterSpacing};
  ${color};
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${getThemeColor('black')};
  
  ${StyledArrowInCircle} {
    ${transitionDefault};
  
    svg {
      ${transitionDefault};
    }
  }
  
  &:hover {
  transition: none;
    color: ${getThemeColor('primary')};
    
    ${StyledArrowInCircle} {
      border-color: ${getThemeColor('primary')};
      background-color: ${getThemeColor('primary')};
      transition: none;
    
      svg {
        stroke: #fff;
        transition: none;
      }
    }
  }
  
  ${(props) =>
    props.white &&
    css`
      color: #fff;

      &:hover {
        color: #fff;

        ${StyledArrowInCircle} {
          background-color: #fff;
          border-color: #fff;

          svg {
            stroke: ${getThemeColor('black')(props)};
          }
        }
      }
    `};

  ${(props) =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
    `};

  ${(props) =>
    props.spread &&
    css`
      justify-content: space-between;
    `};
`;

const LinkWithArrowContainerHref = styled.a`
${transitionDefault};
  ${display};
  ${position}
  ${space}
  ${fontSize}
  ${fontWeight}
  ${lineHeight}
  ${width};
  ${maxWidth};
  ${alignSelf};
  ${justifySelf};
  ${textAlign};
  ${left};
  ${letterSpacing};
  ${color};
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${getThemeColor('black')};
  
  ${StyledArrowInCircle} {
    ${transitionDefault};
  
    svg {
      ${transitionDefault};
    }
  }
  
  &:hover {
  transition: none;
    color: ${getThemeColor('primary')};
    
    ${StyledArrowInCircle} {
      border-color: ${getThemeColor('primary')};
      background-color: ${getThemeColor('primary')};
      transition: none;
    
      svg {
        stroke: #fff;
        transition: none;
      }
    }
  }
  
  ${(props) =>
    props.white &&
    css`
      color: #fff;

      &:hover {
        color: #fff;

        ${StyledArrowInCircle} {
          background-color: #fff;
          border-color: #fff;

          svg {
            stroke: ${getThemeColor('black')(props)};
          }
        }
      }
    `};

  ${(props) =>
    props.reverse &&
    css`
      flex-direction: row-reverse;
    `};

  ${(props) =>
    props.spread &&
    css`
      justify-content: space-between;
    `};
`;

const StyledText = styled(Text)`
  font-weight: bold;
  letter-spacing: 0;
`;

function LinkWithArrow({
  children,
  white,
  fontSizeLarge,
  fontSizeExtraSmall,
  small,
  smallIcon,
  linkMode,
  to,
  ...rest
}) {
  if (linkMode) {
    return (
      <LinkWithArrowContainerHref
        href={to}
        target={'_blank'}
        download={linkMode}
        white={white}
        {...rest}
      >
        <StyledArrowInCircle white={white} small={small || smallIcon} />
        <StyledText
          html={children}
          fontSizeLarge={fontSizeLarge}
          fontSizeExtraSmall={small || fontSizeExtraSmall}
          white={white}
        />
      </LinkWithArrowContainerHref>
    );
  } else {
    return (
      <LinkWithArrowContainer to={to} white={white} {...rest}>
        <StyledArrowInCircle white={white} small={small || smallIcon} />
        <StyledText
          html={children}
          fontSizeLarge={fontSizeLarge}
          fontSizeExtraSmall={small || fontSizeExtraSmall}
          white={white}
        />
      </LinkWithArrowContainer>
    );
  }
}
export default LinkWithArrow;
