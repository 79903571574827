import React from 'react';
import { Location } from '@reach/router';

function withLocation(WrappedComponent, locationPropName = 'location') {
  return function WithLocation(props) {
    return (
      <Location>
        {({ location }) => {
          const locationProps = {
            [locationPropName]: location,
          };
          return <WrappedComponent {...props} {...locationProps} />;
        }}
      </Location>
    );
  };
}

export default withLocation;
