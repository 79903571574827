import React from 'react';

import { useGlobalCtaBlockData } from '../hooks/use-global-cta-block-data';
import StoryblokCtaBanner from './storyblok/adapters/storyblok-cta-banner-adapter';

function GlobalCtaBlock() {
  const data = useGlobalCtaBlockData();

  return <StoryblokCtaBanner {...data} />;
}

export default GlobalCtaBlock;
