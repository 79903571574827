import React from 'react';
import styled, { css } from 'styled-components';
import {
  space,
  background,
  color,
  fontSize,
  height,
  width,
  fontWeight,
  lineHeight,
  position,
  flex,
  flexDirection,
  justifyContent,
  alignItems,
  grid,
  justifyItems,
  gridGap,
  gridColumnGap,
  gridRowGap,
  gridRow,
  gridAutoFlow,
  gridColumn,
  maxWidth,
  alignSelf,
  justifySelf,
  textAlign,
  border,
  minHeight,
  left,
  letterSpacing,
  display,
  overflow,
  size,
  flexWrap,
  flexShrink,
  maxHeight,
} from 'styled-system';

import mq from '../../utils/mq';
import convertHtml from '../../utils/convert-html';
import { getThemeColor, styledLink } from '../../utils/styles';

export const Box = styled('div')`
  ${display}
  ${space}
  ${height}
  ${width}
  ${size}
  ${fontSize}
  ${fontWeight}
  ${color}
  ${position}
  ${background}
  ${maxWidth}
  ${minHeight}
  ${maxHeight}
  ${alignSelf};
  ${justifySelf};
  ${textAlign};
  ${flexShrink};
  ${flex};
  ${border};
  ${overflow};
  ${gridColumn};
  ${gridRow};
`;

Box.propTypes = {
  ...space.propTypes,
  ...width.propTypes,
  ...fontSize.propTypes,
  ...color.propTypes,
  ...position.propTypes,
  ...background.propTypes,
  ...gridColumn.propTypes,
  ...gridRow.propTypes,
  ...maxWidth.propTypes,
  ...alignSelf.propTypes,
  ...justifySelf.propTypes,
};

export const Flex = styled(Box)`
  display: flex;
  ${flex};
  ${alignItems};
  ${justifyContent};
  ${flexDirection};
  ${flexWrap};
  ${flexShrink};
`;
Flex.displayName = 'Flex';
Flex.propTypes = {
  ...flex.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
  ...flexDirection.propTypes,
};

export const Grid = styled(Box)`
  display: grid;
  ${grid};
  ${alignItems};
  ${justifyContent};
  ${justifyItems};
  ${gridColumnGap};
  ${gridRowGap};
  ${gridAutoFlow};
`;

Grid.propTypes = {
  ...grid.propTypes,
  ...alignItems.propTypes,
  ...justifyContent.propTypes,
  ...justifyItems.propTypes,
  ...gridGap.propTypes,
  ...gridColumnGap.propTypes,
  ...gridRowGap.propTypes,
  ...gridAutoFlow.propTypes,
};

const StyledText = styled('div')`
  ${display};
  ${position}
  ${space}
  ${fontWeight}
  ${lineHeight}
  ${color};
  ${width};
  ${maxWidth};
  ${alignSelf};
  ${justifySelf};
  ${textAlign};
  ${left};
  letter-spacing: -0.01em;
  font-size: 16px;
  ${fontSize}
  ${letterSpacing};
  
  ${(props) =>
    props.bold &&
    css`
      font-weight: 700;
    `};
  
  ${(props) =>
    props.fontSizeExtraLarge &&
    css`
      font-size: 24px;

      ${mq.mobile(css`
        font-size: 20px;
      `)};
    `}
  
  ${(props) =>
    props.fontSizeLarge &&
    css`
      font-size: 20px;

      ${mq.mobile(css`
        font-size: 18px;
      `)};
    `}
  
  ${(props) =>
    props.fontSizeMedium &&
    css`
      font-size: 18px;

      ${mq.mobile(css`
        font-size: 16px;
      `)};
    `};
  
  ${(props) =>
    props.fontSizeSmall &&
    css`
      font-size: 15px;
    `};
  
  ${(props) =>
    props.fontSizeExtraSmall &&
    css`
      font-size: 14px;
    `}

  ${(props) =>
    props.bold &&
    css`
      font-weight: 700;
    `};
  
  ${(props) =>
    props.semibold &&
    css`
      font-weight: 600;
    `};

  ${(props) =>
    props.italic &&
    css`
      font-style: italic;
    `};
  
  ${(props) =>
    props.grey &&
    css`
      color: ${getThemeColor('grey')(props)};
    `};
  
  ${(props) =>
    props.greyAlt &&
    css`
      color: #959595;
    `};

  ${(props) =>
    props.lightGrey &&
    css`
      color: #d4d4d4;
    `};
`;

StyledText.propTypes = {
  ...space.propTypes,
  ...fontSize.propTypes,
  ...fontWeight.propTypes,
  ...lineHeight.propTypes,
  ...color.propTypes,
  ...width.propTypes,
  ...maxWidth.propTypes,
  ...alignSelf.propTypes,
  ...justifySelf.propTypes,
};

export const Text = React.forwardRef(({ html, ...rest }, ref) => {
  return (
    <React.Fragment>
      {html !== undefined ? (
        <React.Fragment>
          {html !== '' ? (
            <StyledText
              dangerouslySetInnerHTML={convertHtml(html)}
              {...rest}
              ref={ref}
            />
          ) : (
            <StyledText {...rest} ref={ref} />
          )}
        </React.Fragment>
      ) : (
        <StyledText {...rest} ref={ref} />
      )}
    </React.Fragment>
  );
});

export const Paragraph = styled(Text)`
  line-height: 1.6;
  font-size: 18px;
  ${lineHeight};
  ${fontSize};

  ul {
    padding: 0;

    li {
      margin-bottom: 30px;

      ${mq.mobile(css`
        margin-bottom: 15px;
      `)};
    }
  }

  a {
    ${styledLink};
    text-decoration: none;
  }

  b {
    font-weight: 600;
  }

  p {
    margin-bottom: 25px;

    img {
      margin-top: 60px;
      margin-bottom: 65px;
    }
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    margin-top: 75px;
    margin-bottom: 20px;
    color: ${getThemeColor('black')};
  }

  blockquote {
    position: relative;
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 47px;
    font-size: 20px;
    font-weight: 700;
    padding-left: 40px;
    color: ${getThemeColor('black')};
    font-style: italic;

    &:before {
      content: '“';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      padding-top: 7px;
      padding-left: 24px;
      border-left: 4px solid ${getThemeColor('primary')};
    }

    p {
      &:last-of-type {
        margin-bottom: 0;

        &:after {
          content: '”';
          display: inline;
        }
      }
    }

    ${mq.mobile(css`
      font-size: 18px;
    `)};
  }

  img {
    display: block;
    max-width: 100%;
  }

  ${(props) =>
    props.fontSizeMedium &&
    css`
      font-size: 20px;
    `};

  ${(props) =>
    props.fontSizeSmall &&
    css`
      font-size: 16px;
    `}

  ${(props) =>
    props.fontSizeExtraSmall &&
    css`
      font-size: 14px;
    `};

  ${(props) =>
    props.smallMargin &&
    css`
      p {
        margin-bottom: 7px;
      }
    `};
`;
