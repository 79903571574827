import React from 'react';
import styled from 'styled-components';

import ArrowInCircle from '../arrow-in-circle';
import Link from '../link';
import parseStoryblokLink from '../../utils/parse-storyblok-link';

const MobileMenuItemContainer = styled(Link)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  width: 100%;
  padding-top: 24px;
  border-bottom: 1px solid #333;
`;

const ItemName = styled.div`
  font-weight: bold;
  font-size: 17px;
  line-height: 44px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
`;

function MobileMenuItem({ name, link, submenu, openSubpage, onLinkClick }) {
  function handleClick(e) {
    if (!submenu) {
      onLinkClick();
      return;
    }

    e.preventDefault();

    openSubpage(submenu);
  }
  return (
    <MobileMenuItemContainer to={parseStoryblokLink(link)} onClick={handleClick}>
      <ItemName>{name}</ItemName>
      <ArrowInCircle white large />
    </MobileMenuItemContainer>
  );
}

export default MobileMenuItem;
