import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

function DropdownPortal({ children }) {
  const containerRef = useRef(null);

  useEffect(() => {
    setContainerRef();
  });

  function setContainerRef() {
    containerRef.current = document.getElementById('nav-modal-container');
  }

  if (!containerRef.current) {
    return null;
  }

  return ReactDOM.createPortal(children, containerRef.current);
}

export default DropdownPortal;
