import React from 'react';
import styled, { css } from 'styled-components';

import mq from '../utils/mq';
import { hoverDefault } from '../utils/styles';

const StyledBurger = styled.div`
  ${hoverDefault};
  position: relative;
  cursor: pointer;
  display: none;

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    right: -10px;
    bottom: -10px;
    left: -10px;
  }

  ${mq.tablet(css`
    display: block;
    z-index: 2;
  `)};
`;

const Line = styled.div`
  width: 22px;
  height: 2px;
  background-color: #fff;
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }

  ${mq.mobile(css`
    width: 16px;
  `)};
`;

function Burger() {
  return (
    <StyledBurger>
      <Line />
      <Line />
      <Line />
    </StyledBurger>
  );
}

export default Burger;
