import { useEffect, useRef } from 'react';

export function useOutsideClick(handler, { node, enabled = true } = {}) {
  const clickEventIsAdded = useRef(false);
  useEffect(() => {
    if (!node) {
      removeClickEvent();
      return;
    }

    if (enabled) {
      addClickEvent();
    } else {
      removeClickEvent();
    }

    return () => {
      removeClickEvent();
    };
  }, [enabled, node]);

  function addClickEvent() {
    if (clickEventIsAdded.current) {
      return;
    }
    clickEventIsAdded.current = true;
    document.addEventListener('click', handleDocumentClick);
  }

  function removeClickEvent() {
    if (!clickEventIsAdded.current) {
      return;
    }
    clickEventIsAdded.current = false;
    document.removeEventListener('click', handleDocumentClick);
  }

  function handleDocumentClick(event) {
    const isClickInside = node && node.contains(event.target);

    if (!isClickInside && enabled) {
      handler();
    }
  }
}
