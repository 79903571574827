import { FunctionComponent } from 'react'
import styled, { css } from 'styled-components'

import {
  CONTAINER_WIDTH,
  CONTAINER_WIDTH_LARGE,
  OFFSET_SIDE,
  OFFSET_SIDE_SM,
  OFFSET_SIDE_XSM
} from '../constants'
import mq from '../utils/mq'
import { Box } from './UIKit/system'

const StyledContainer = styled(Box)`
  width: 100%;
  max-width: ${CONTAINER_WIDTH + OFFSET_SIDE * 2}px;
  margin-left: auto;
  margin-right: auto;
  border-left: ${OFFSET_SIDE}px solid transparent;
  border-right: ${OFFSET_SIDE}px solid transparent;

  ${mq.mobile(css`
    border-left-width: ${OFFSET_SIDE_SM}px;
    border-right-width: ${OFFSET_SIDE_SM}px;
  `)};

  ${mq.mobileSmall(css`
    border-left-width: ${OFFSET_SIDE_XSM}px;
    border-right-width: ${OFFSET_SIDE_XSM}px;
  `)};

  ${(props) =>
    props.relative &&
    css`
      position: relative;
    `};

  ${(props) =>
    props.large &&
    css`
      max-width: ${CONTAINER_WIDTH_LARGE + OFFSET_SIDE * 2}px;
    `};

  ${(props) =>
    props.medium &&
    css`
      max-width: ${1156 + OFFSET_SIDE * 2}px;
    `};

  ${(props) =>
    props.narrow &&
    css`
      max-width: ${895 + OFFSET_SIDE * 2}px;
    `};
`

const Container: FunctionComponent<{ large: boolean }> = StyledContainer

export default Container
