import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { AnimatePresence, motion } from 'framer-motion';

import Container from '../container';
import mq from '../../utils/mq';
import MobileMenuItem from './mobile-menu-item';
import MobileSubmenu from './mobile-submenu';
import { withResponsiveContext } from '../responsive';
import CloseIcon from '../../images/svg/close-cross.svg';
import Logo from '../../images/svg/logo.svg';
import parseStoryblokLink from '../../utils/parse-storyblok-link';
import Link from '../link';

const StyledMobileMenu = styled.div``;

const MobileNavigationContainer = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #161616;
  color: #fff;
  z-index: 150;
  overflow-y: auto;
  overflow-x: hidden;
`;

const NavigationInnerContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const TopBar = styled.div`
  width: 100vw;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
  position: sticky;
  top: 0;
  margin-top: 20px;
  background-color: #161616;
  overflow: hidden;
`;

const LogoContainer = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CloseIconContainer = styled.div`
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledLogo = styled(Logo)`
  fill: #161616;

  ${mq.tablet(css`
    fill: #fff;
  `)};

  ${mq.mobile(css`
    position: relative;
    top: 1px;
    width: 54px;
  `)};
`;

const MenuContainer = styled(Container)``;

const MenuTitle = styled.div`
  font-weight: bold;
  font-size: 24px;
  line-height: 46px;
  letter-spacing: -0.02em;
  color: #ffffff;
  margin-bottom: 36px;
  margin-top: 52px;
`;

const MenuItemsContainer = styled(motion.div)``;

const WhiteOverlay = styled(motion.div)`
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #dddddd7d;
  z-index: 90;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SubMenuItemsContainer = styled(motion.div)`
  margin-top: 56px;
  display: flex;
  flex-direction: column;
`;

const SubMenuItem = styled(motion.div)`
  font-size: 16px;
  display: flex;
  align-items: center;
  letter-spacing: -0.02em;
  color: #dddddd;
  margin-bottom: 24px;

  &:last-of-type {
    margin-bottom: 0px;
  }
`;

const MobileMenuItemConatiner = styled(motion.div)``;

const menuAnim = {
  false: {
    opacity: 1,
    height: '0',
    y: '-100vh',
    transition: {
      duration: 0.45,
      ease: 'easeOut',
    },
  },
  true: {
    opacity: 1,
    height: '100vh',
    y: '0',
    transition: {
      duration: 0.45,
      ease: 'easeOut',
    },
  },
};

const navigationAnim = {
  false: {
    opacity: 0,

    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
  true: {
    opacity: 1,

    transition: {
      duration: 0.2,
      ease: 'easeOut',
    },
  },
};

const menuContainerAnim = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
      when: 'afterChildren',
      delayChildren: 0.35,
    },
  },
};

const subMenuContainerAnim = {
  hidden: { opacity: 1 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.05,
      when: 'afterChildren',
      delayChildren: 0.65,
    },
  },
};

const menuItemAnim = {
  hidden: { opacity: 0, y: 20 },
  show: { opacity: 1, y: 0 },
};

function MobileNavigation({ show, data, onClose }) {
  const [navigating, setNavigating] = useState(false);
  const [selectedSubmenu, setSelectedSubmenu] = useState(null);
  const { items, additionalItems } = data;

  function handleMenuClose(pageNavigating) {
    if (pageNavigating) {
      setNavigating(false);
    }
    closeSubmenu();
    onClose();
  }

  useEffect(() => {
    document.documentElement.style.overflow = show ? 'hidden' : '';
  }, [show]);

  function handleLinkClick() {
    handleMenuClose(true);
  }

  function openSubpage(submenu) {
    setSelectedSubmenu(submenu);
  }

  function closeSubmenu() {
    setSelectedSubmenu(null);
  }

  return (
    <AnimatePresence>
      <StyledMobileMenu>
        {navigating ? (
          <WhiteOverlay
            key="mobileNavOverlay"
            initial="false"
            animate={navigating ? 'true' : 'false'}
            variants={navigationAnim}
          />
        ) : null}
        <MobileNavigationContainer
          key="mobileNavContainer"
          initial="false"
          animate={show ? 'true' : 'false'}
          variants={menuAnim}
        >
          <NavigationInnerContainer>
            <TopBar>
              <LogoContainer>
                <StyledLogo />
              </LogoContainer>
              <CloseIconContainer>
                <CloseIcon stroke={'#fff'} onClick={handleMenuClose} />
              </CloseIconContainer>
            </TopBar>
            <MenuContainer pb={60}>
              <React.Fragment>
                <MenuTitle>Menu</MenuTitle>
                <MenuItemsContainer
                  variants={menuContainerAnim}
                  initial="hidden"
                  animate={show ? 'show' : 'hidden'}
                >
                  {items.map((item, index) => (
                    <MobileMenuItemConatiner
                      variants={menuItemAnim}
                      key={index}
                    >
                      <MobileMenuItem
                        key={index}
                        openSubpage={openSubpage}
                        onLinkClick={handleLinkClick}
                        {...item}
                      />
                    </MobileMenuItemConatiner>
                  ))}
                </MenuItemsContainer>
                {additionalItems && (
                  <SubMenuItemsContainer
                    variants={subMenuContainerAnim}
                    initial="hidden"
                    animate={show ? 'show' : 'hidden'}
                  >
                    {additionalItems.map((item, index) => (
                      <SubMenuItem key={index} variants={menuItemAnim}>
                        <Link to={parseStoryblokLink(item.link)}>
                          {item.name}
                        </Link>
                      </SubMenuItem>
                    ))}
                  </SubMenuItemsContainer>
                )}
              </React.Fragment>
            </MenuContainer>
          </NavigationInnerContainer>
        </MobileNavigationContainer>
        <MobileSubmenu
          show={!!selectedSubmenu}
          {...(selectedSubmenu || {})}
          onClose={closeSubmenu}
        />
      </StyledMobileMenu>
    </AnimatePresence>
  );
}

export default withResponsiveContext(MobileNavigation);
