import { css } from 'styled-components';
import mq from './mq';

export const fontFamilySans = css`
  font-family: Muli, 'Helvetica Neue', Helvetica, sans-serif, Arial;
  letter-spacing: -0.02em;
`;

export const buttonReset = css`
  ${fontFamilySans};
  border: none;
  background-color: transparent;
  border-radius: 0;
  -webkit-appearance: none;
  text-decoration: none;
  user-select: none;
`;

export const transitionDefault = css`
  transition-property: transform, opacity, background-color, color, border-color,
    stroke, fill;
  transition-duration: 0.35s;
  transition-timing-function: ease-out;
`;

export const hoverDefault = css`
  ${transitionDefault};

  &:hover {
    opacity: 0.8;
    transition: none;
  }

  &:active {
    opacity: 0.6;
  }
`;

export const hoverScaleDefault = css`
  ${transitionDefault};
  will-change: transform;

  &:hover {
    transform: scale(1.05);
    transition-duration: 0.15s;
  }

  &:active {
    transform: scale(1.1);
  }
`;

export const inputReset = () => css`
  ${fontFamilySans};
  width: 100%;
  border: none;
  border-radius: 0;
  -webkit-appearance: none;
  background-color: transparent;

  &:focus {
    outline: none;
  }

  &::-ms-clear {
    display: none;
  }
`;

export const inputLabel = (props) => css`
  display: block;
  margin-bottom: 32px;
`;

const inputCommonInvalid = (props) => css`
  border-color: ${getThemeColor('danger')(props)};
`;

export const inputCommon = (props) => css`
  display: block;
  border: 1.5px solid #d4d4d4;
  min-height: 48px;
  font-size: 14px;
  transition: border-color 0.25s ease-out, color 0.25s ease-out;
  color: ${getThemeColor('black')};
  padding: 0 17px;

  &::placeholder {
    color: #959595;
  }

  &:focus {
    border-color: #aaaaaa;
  }

  ${props.invalid &&
  css`
    ${inputCommonInvalid(props)};
  `};

  &.invalid {
    ${inputCommonInvalid(props)};
  }

  ${mq.mobile(css`
    min-height: 36px;
    font-size: 18px;
  `)};

  ${(props) =>
    props.darkBackground &&
    css`
      color: #fff;
      border-color: #474747;

      &::placeholder {
        color: #d4d4d4;
      }
    `};
`;

const inputCommonAltInvalid = (props) => css`
  border-bottom-color: ${getThemeColor('danger')(props)};
`;

export const inputCommonAlt = (props) => css`
  display: block;
  color: rgba(255, 255, 255, 0.9);
  border-bottom: 1px solid #474747;
  min-height: 60px;
  font-size: 16px;
  transition: border-color 0.25s ease-out, color 0.25s ease-out;

  &::placeholder {
    color: #d4d4d4;
  }

  &:focus {
    color: #fff;
    border-bottom-color: #fff;
  }

  ${props.invalid &&
  css`
    ${inputCommonAltInvalid(props)};
  `};

  &.invalid {
    ${inputCommonAltInvalid(props)};
  }

  ${mq.mobile(css`
    min-height: 36px;
    font-size: 18px;
  `)};
`;

export const darkBackground = () => css`
  background-color: ${getThemeColor('black')};
  color: #fff;
`;

export const lightGreyBackground = () => css`
  background-color: ${getThemeColor('lightGrey')};
`;

export const styledLink = () => css`
  ${hoverDefault};
  text-decoration: underline;
  color: ${getThemeColor('primary')};
  font-weight: 700;
`;

export function getThemeColor(color) {
  return function (props) {
    return props.theme.colors[color];
  };
}

export const supportsGrid = (content) => css`
  @supports (display: grid) {
    ${content};
  }
`;

export const button = (props) => css`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 56px;
  border: 1.5px solid transparent;
  padding: 0 45px;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: -0.01em;
  background-color: ${getThemeColor('primary')(props)};
  color: #fff;
  font-weight: 700;
  max-width: 100%;
`;

export const textarea = () => css`
  padding-top: 1em;
  padding-bottom: 1em;
  resize: none;
  line-height: 1.75;
`;

export const inputSelect = () => css`
  position: relative;
  border: 1px solid #474747;
  background-color: ${getThemeColor('black')};
  color: #fff;
  height: 64px;
  border-radius: 0;
  padding: 0 29px 0 35px;
  margin-top: 10px;
`;

export const inputSelectWhite = () => css`
  background-color: #fff;
  border: none;
  color: #666;
`;
